import logoBlackText from './onezee-logo-black-text.svg';
import logoChristmas from './onezee-logo-christmas.svg';
import logoWhiteText from './onezee-logo-white-text.svg';

type LogoProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  variant?: 'blackText' | 'whiteText' | 'christmas';
};

export function LogoWebsite({
  className,
  variant = 'blackText',
  ...props
}: LogoProps) {
  let logoSrc = logoBlackText;

  switch (variant) {
    case 'whiteText':
      logoSrc = logoWhiteText;
      break;
    case 'christmas':
      logoSrc = logoChristmas;
      break;
  }

  return <img {...props} src={logoSrc} alt="Onezee" className={className} />;
}
